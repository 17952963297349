import './GalleryBlock.scss';
import GalleryItem from './GalleryItem/GalleryItem';
import { IGalleryBlock } from '../../../types/types';

const GalleryBlock: React.FC<IGalleryBlock> = (props) => {

  const sortedExhibits = props.exhibits?.sort((a, b) => a.attributes.Order - b.attributes.Order);

  return (
    <section className='gallery-block' id={props.hash}> 
      <div className='gallery-block-head'>
        <h2 className='gallery-block-title'>{props.title}</h2>
        <p className='gallery-block-description'>{props.description}</p>
      </div>

      <div className='gallery-block-content'>
        {
          sortedExhibits?.map((exhibit, index) => (
            <GalleryItem 
              key={exhibit.id}
              title={exhibit.attributes.Title}
              hash={exhibit.attributes.hash}
              assets={exhibit.attributes.Images}
              price={exhibit.attributes.Price}
              army={exhibit.attributes.Army}
              width={exhibit.attributes.Width}
              length={exhibit.attributes.Lenght}
              height={exhibit.attributes.Height}
              weight={exhibit.attributes.Weight}
              years={exhibit.attributes.Years}
              description={exhibit.attributes.Description}
              authors={exhibit.attributes.Author}
              materials={exhibit.attributes.Material}
              VideoURL={exhibit.attributes.VideoURL}
              VideoImage={exhibit.attributes.VideoImage}
            />
          ))
        }
      </div>
    </section>
  )
};

export default GalleryBlock;
