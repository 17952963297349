import './GalleryItem.scss';
import GallerySlider from './GallerySlider/GallerySlider';
import { IGalleryItem } from '../../../../types/types';

const GalleryItem: React.FC<IGalleryItem> = ( props ) => {
  return (
    <div className='gallery-item' id={props.hash}>
        <GallerySlider
          key={props.title}
          assets={props.assets}
          price={props.price}
          army={props.army}
          width={props.width}
          length={props.length}
          height={props.height}
          weight={props.weight}
          years={props.years}
          VideoURL={props.VideoURL}
          VideoImage={props.VideoImage}
        />

        <div className='gallery-item-info'>
          <h3>{props.title}</h3>

          <div>
            {
              props.authors.length === 1 ?
                (<span>Автор</span>) :
              props.authors.length > 1 ?
                (<span>Автори</span>) :
                null
            }
          
            {
              props.authors.map((author, index) => (
                <span className='gallery-item-author' key={index}>{author.Name}</span>
              ))
            }
          </div>
          
          <p>{props.description}</p>

          <div>
            {
              props.materials.length === 1 ?
                (<span>Матеріал</span>) :
              props.materials.length > 1 ?
                (<span>Матеріали</span>) :
                null
            }

            {
              props.materials.map((material, index) => (
                <span className='gallery-item-material' key={index}>{material.Name}</span>
              ))
            }
          </div>
        </div>
    </div>
  )
};

export default GalleryItem;
