import './GalleryVideo.scss';
import ReactPlayer from 'react-player';
import React, {useRef, useState, useLayoutEffect} from 'react';
import { useMediaQuery } from '../../../../../../hooks/useMediaQuery';
import PlayIcon from '../../../../../../assets/icon-play.svg';
import CrossIcon from '../../../../../../assets/cross-icon.svg';



const GalleryVideo = React.forwardRef((props : any, ref : any) => {
    const isMobile = useMediaQuery('(max-width: 700px)');

    const [isFullScreen, setFullScreen] = useState(false);
    const videoRef = useRef<IVideoElement>(null);
    const videoContainerRef = useRef<HTMLDivElement>(null);
    const videoContainerInnerRef = useRef<HTMLDivElement>(null);

    const enterFullScreen = () => {
        const elem = videoRef.current!;

        if (elem.requestFullscreen) {
            elem.requestFullscreen();
        } else if (elem.webkitRequestFullscreen) {
            /* Safari */
            elem.webkitRequestFullscreen();
            
        } else if (elem.webkitEnterFullscreen) {
            /* Safari */
            elem.webkitEnterFullscreen();
        } else if (elem.msRequestFullscreen) {
            /* IE11 */
            elem.msRequestFullscreen();
            
        } else if (elem.mozRequestFullScreen) {
            /* Mozila */
            elem.mozRequestFullScreen();
        };
    };

    function exitFullscreen() {
        const elem = document;

        if (elem.exitFullscreen) {
            elem.exitFullscreen();
            
        } else if (elem.mozCancelFullScreen) {
            elem.mozCancelFullScreen();
            
        } else if (elem.webkitExitFullscreen) {
            elem.webkitExitFullscreen(); 
        };
    };

    const handleFullScreen = () => {
        if (isMobile) {   
            videoRef.current!.style.width = '100%';
            videoRef.current!.style.height = '100%';
            videoRef.current!.style.zIndex = '1';
            videoRef.current!.style.left = '0';
            document.body.style.overflow = 'hidden';
            videoContainerRef.current!.style.width = '100%';
            videoContainerRef.current!.style.height = '100vh';
            ref.current!.style.width = '100vw';
            ref.current!.style.height = '100vh';
            ref.current!.style.zIndex = '2000';
            ref.current!.style.position = 'fixed';
            ref.current!.style.backgroundColor = 'grey';
            ref.current!.style.top = '0';
            ref.current!.style.left = '0';
            videoContainerInnerRef.current!.style.display = 'none';

            props.setVideoOpen(true);
        } else {
            enterFullScreen();
        };
        
        setFullScreen(true);
    }

    const handleFullScreenClose = () => {
        if (window.innerWidth < 700 && window.innerWidth > 650) { 
            videoRef.current!.style.width = '100px';
            videoRef.current!.style.height = '50px';
            videoRef.current!.style.zIndex = '-1';
            videoRef.current!.style.left = '10px';
            document.body.style.overflow = 'unset';
            videoContainerRef.current!.style.width = '572px';
            videoContainerRef.current!.style.height = '634px';
            ref.current!.style.width = '572px';
            ref.current!.style.height = '634px';
            ref.current!.style.zIndex = 'unset';
            ref.current!.style.position = 'relative';
            ref.current!.style.backgroundColor = 'none';
            ref.current!.style.top = 'unset';
            ref.current!.style.left = 'unset';
            videoContainerInnerRef.current!.style.display = 'flex';

            props.setVideoOpen(false);
        } else if (window.innerWidth < 650 && window.innerWidth > 450) {
            videoRef.current!.style.width = '100px';
            videoRef.current!.style.height = '50px';
            videoRef.current!.style.zIndex = '-1';
            videoRef.current!.style.left = '10px';
            document.body.style.overflow = 'unset';
            videoContainerRef.current!.style.width = '100%';
            videoContainerRef.current!.style.height = '634px';
            ref.current!.style.width = '100%';
            ref.current!.style.height = '634px';
            ref.current!.style.zIndex = 'unset';
            ref.current!.style.position = 'relative';
            ref.current!.style.backgroundColor = 'none';
            ref.current!.style.top = 'unset';
            ref.current!.style.left = 'unset';
            videoContainerInnerRef.current!.style.display = 'flex';

            props.setVideoOpen(false);
        } else if (window.innerWidth < 450) {
            videoRef.current!.style.width = '100px';
            videoRef.current!.style.height = '50px';
            videoRef.current!.style.zIndex = '-1';
            videoRef.current!.style.left = '10px';
            document.body.style.overflow = 'unset';
            videoContainerRef.current!.style.width = '100%';
            videoContainerRef.current!.style.height = '380px';
            ref.current!.style.width = '100%';
            ref.current!.style.height = '380px';
            ref.current!.style.zIndex = 'unset';
            ref.current!.style.position = 'relative';
            ref.current!.style.backgroundColor = 'none';
            ref.current!.style.top = 'unset';
            ref.current!.style.left = 'unset';
            videoContainerInnerRef.current!.style.display = 'flex';

            props.setVideoOpen(false);
        };

        setFullScreen(false);

        if(window.innerWidth > 850) {
            exitFullscreen();
        };
    }

    const handleFullScreenChange = () => {
        if (window.innerHeight !== videoRef.current!.offsetHeight) {
            setFullScreen(false);
        }
    }

    useLayoutEffect(() => {
        const currentVideoRef = videoRef.current;

        currentVideoRef!.addEventListener('fullscreenchange', handleFullScreenChange);
        currentVideoRef!.addEventListener('webkitfullscreenchange', handleFullScreenChange);
    
        return () => {
            currentVideoRef!.removeEventListener('fullscreenchange', handleFullScreenChange);
            currentVideoRef!.removeEventListener('webkitfullscreenchange', handleFullScreenChange);
        };
    }, []);


    return (
        <div className='gallery-video' ref={videoContainerRef}>
            <div className='gallery-video-media-container' ref={(videoRef as unknown) as React.RefObject<HTMLDivElement>}>
                <img src={CrossIcon} alt={'Close Video Button'} className='cross-icon' onClick={handleFullScreenClose}/>

                <ReactPlayer
                    
                    loading="lazy"
                    className='gallery-video-player'
                    url={props.video}
                    controls={true}
                    loop={false}
                    playing={isFullScreen ? true : false}
                    playsinline={true}
                    volume={0.5}
                    muted={isFullScreen ? true : true}
                    config={{ vimeo: { playerOptions: { transcript: false, pip: false}}}}
                    onEnded={() => {
                        if(isFullScreen) {
                            handleFullScreenClose();
                        }
                    }}
                />
            </div>

            <div className='gallery-video-container'  onClick={handleFullScreen} ref={videoContainerInnerRef}>
                <div className='gallery-video-button'></div>

                <img className='gallery-video-play-icon'src={PlayIcon} alt={'Play Icon'}/>

                <img className='gallery-video-phono' src={props.photo} alt={'Video'}/>
            </div>
        </div>
    )
});

export default GalleryVideo;
