import './GallerySlider.scss';
import GalleryVideo from './GalleryVideo/GalleryVideo';
import Slider from 'react-slick';
import { IGallerySlider, IGalleryAsset } from '../../../../../types/types';
import React, {useRef, useState} from 'react';
import classNames from 'classnames';


const GallerySlider: React.FC<IGallerySlider> = (props) => {
  const [isVideoOpen, setVideoOpen] = useState(false);

  const settings = {
    dots: false,
    arrows: !isVideoOpen,
    swipe: !isVideoOpen,
    fade: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  const sliderRef = useRef<HTMLDivElement>(null);

  // Styles

  const armyStyles = classNames('gallery-item-army', {
    hide: isVideoOpen
  });

  const priceStyles = classNames('gallery-item-price', {
    hide: isVideoOpen
  });
  
  return (
    <div className='gallery-slider' ref={sliderRef}>
      <Slider {...settings}>
        {
          props.VideoURL && props.VideoImage ? (
            <GalleryVideo
              video={props.VideoURL}
              photo={props.VideoImage.data.attributes.url}
              ref={sliderRef}
              setVideoOpen={setVideoOpen}
            />
          ) : null
        }

        {
          props.assets.data ?
          props.assets.data.map((asset : IGalleryAsset, index : number) => (
            <div key={index}>
              <div className='gallery-slider-item' key={index}>
                <img src={asset.attributes.url} alt={'Exhibit'}></img>
              </div>
            </div>
          )) : null
        }
      </Slider>
      
      {
        props.price !== null ? (
          <div className={priceStyles}>${props.price}</div>
        ) : null
      }

      {
        props.army !== null ? (
          <div className={armyStyles}>{props.army}% на ЗСУ</div>
        ) : null
      }

      <div className='gallery-item-short-info'>
        <span>{props.width} x {props.length} {Number(props.height) !== 0 ? 'x' : null} {Number(props.height) !== 0 ? props.height : null}</span>
        <span>{props.weight} кг</span>
        <span>{props.years}</span>
      </div>
    </div>
  )
};

export default GallerySlider;
