import './App.scss';
import useScrollToSection from './hooks/useScrollToSection';
import Header from './components/Header/Header';
import WelcomeSection from './components/WelcomeSection/WelcomeSection';
import Footer from './components/Footer/Footer';
import Gallery from './components/Gallery/Gallery';

function App() {
  useScrollToSection();

  return (
      <div className="App">
        <Header />
        <WelcomeSection />
        <Gallery/>
        <Footer/>
      </div>
  );
}

export default App;
