import './Footer.scss';
import FooterLogo from '../../assets/footer-logo.png';

const Footer = () => {
  return (
    <footer className='footer' id='contacts'>
        <div className='footer-container'>
            <div className='footer-head'>
                <span>Ми група художників. Сфера діяльності – образотворче мистецтво, абстрактна, інтуїтивна скульптура. </span>
                <span>Наша мета – Розвиток українського мистецтва</span>
            </div>

            <div className='footer-contacts'>
                <div className='footer-contacts-box-info phone-and-email'>
                    <div className='footer-contacts-box'>
                        <span className='footer-label'>пошта</span>
                        <a href='mailto: illyatrofumchuk@gmail.com'>
                            <span>illyatrofumchuk@gmail.com</span>
                        </a>
                    </div>

                    <div className='footer-contacts-box'>
                        <span className='footer-label'>телефон</span>
                        <a href="tel:+0634774015">
                            <span>+380 (63) 477-40-15</span>
                        </a>
                    </div>
                </div>

                <div className='footer-contacts-box-info socials'>
                    <span className='footer-label'>соціальні мережі</span>
                    <a href='https://www.instagram.com/tigois_project/' target='_blank' rel='noreferrer'>
                        <span>Instagram</span>
                    </a>
                    <a href='https://www.facebook.com/tigoisproject' target='_blank' rel='noreferrer'>
                        <span>Facebook</span>
                    </a>
                </div>

                <img src={FooterLogo} alt={'Footer Logo'}/>
            </div>

            <div className='footer-bottom'>
                <span>TiGois project, 2023. Всі права захищено</span>
                <div className='footer-bottom-dev'>
                    <span>ДИЗАЙН & РОЗРОБКА</span>
                    <a href='https://www.instagram.com/vania_gun/?hl=uk' target='_blank' rel='noreferrer'>
                        <span>Іван вергун</span>
                    </a>
                </div>
            </div>
        </div>
    </footer>
  )
};

export default Footer;
