import './Gallery.scss';
import GalleryBlock from './GalleryBlock/GalleryBlock';
import useFetch from '../../hooks/useFetch';
import {IApiResponseGallery, IApiResponseExhibits} from '../../types/types';

const Gallery = () => {
    const {loading: loadingGalleries, error: errorGalleries, data: dataGalleries} = useFetch('https://strapi-app-mayl.onrender.com/api/galleries?populate=*');

    const {loading: loadingExhibits, error: errorExhibits, data: dataExhibits} = useFetch('https://strapi-app-mayl.onrender.com/api/exhibits?pagination[limit]=-1&populate=*');

    if(loadingGalleries || loadingExhibits) return <div></div>
    if(errorGalleries || errorExhibits) return <div></div>


    const galleryData = (dataGalleries as unknown) as IApiResponseGallery;
    const sortedGalleryData = galleryData.data.sort((a, b) => a.attributes.Order - b.attributes.Order);


    const exhibitData = (dataExhibits as unknown) as IApiResponseExhibits;    
    
    return (
        <main className='gallery' id='gallery'>
            <div className='gallery-container'>
                {
                    sortedGalleryData.map((gallery) => {
                        const filteredExhibits = exhibitData.data.filter(
                            (exhibit) => exhibit.attributes.gallery.data.id === gallery.id
                        );

                        return (
                            <GalleryBlock 
                                key={gallery.id}
                                title={gallery.attributes.Title}
                                description={gallery.attributes.Description}
                                hash={gallery.attributes.hash}
                                exhibits={filteredExhibits}
                            />
                        )
                    }) 
                }

                <div className='gallery-line'></div>
            </div>
        </main>
    )
}

export default Gallery;