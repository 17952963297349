// import { useEffect } from 'react';
// import { useLocation } from 'react-router-dom';
// import { animateScroll as scroll } from 'react-scroll';

// const useScrollToSection = () => {
//   const location = useLocation();

//   useEffect(() => {
//     const hash = location.hash.substring(1);

//     if (hash) {
//       scroll.scrollTo(hash, {
//         duration: 500,
//         smooth: true,
//       });
//     }
//   }, [location]);

//   return null;
// };

// export default useScrollToSection;

import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { scroller } from 'react-scroll';

const useScrollToSection = () => {
  const location = useLocation();

  useEffect(() => {
    const hash = location.hash.substring(1);

    if (hash) {
      setTimeout(() => {
        scroller.scrollTo(hash, {
          duration: 800,
          smooth: 'easeInOutQuad',
        });
      }, 2000);
    }
  }, [location]);

  return null;
};

export default useScrollToSection;