import './Header.scss';
import OpenIcon from '../../assets/open-menu.svg';
import CloseIcon from '../../assets/close-menu.svg';
import NavItem from './NavItem/NavItem';
import { useMediaQuery } from '../../hooks/useMediaQuery';
import { useState, useEffect} from 'react';
import classNames from 'classnames';
import useScrollDirection from '../../hooks/useScrollDirection';
import useFetch from '../../hooks/useFetch';
import { IApiResponseGallery } from '../../types/types';

const Header = () => {
    const [openMenu, setOpenMenu] = useState(false);
    const [headerOpen, setHeaderOpen] = useState(false);

    const scrollDirection = useScrollDirection();

    const isMobile = useMediaQuery('(max-width: 900px)');
    
    // Open/Close Menu Logic

    const handleMenuOpen = () => {
    if (isMobile) {
        setOpenMenu(true);
        setHeaderOpen(true);
    } 
    };

    const handleMenuClose = () => {
        if (isMobile) {
            setOpenMenu(false);

            setTimeout(() => {
                setHeaderOpen(false)
            }, 400);
        } 
    };

    useEffect(() => {
        if (openMenu) {
          document.body.style.overflow = 'hidden';
        } else {
          document.body.style.overflow = 'unset';
        }
    
        return () => {
          document.body.style.overflow = 'unset';
        };
    }, [openMenu]);

    // Get Nav Item From Strapi

    const {loading, error, data} = useFetch('https://strapi-app-mayl.onrender.com/api/galleries');

    if(loading) return <div></div>
    if(error) return <div></div>

    const navDataAPI = (data as unknown) as IApiResponseGallery;

    const filteredNavDataAPI = navDataAPI.data.filter(item => item.attributes.InHeader);

    const sortedData = filteredNavDataAPI.sort((a, b) => a.attributes.Order - b.attributes.Order);

    const navData = [
        {
            text: "ГАЛЕРЕЯ",
            id: "gallery"
        },
    
        ...sortedData.map((nav) => ({
            text: nav.attributes.Title,
            id: nav.attributes.hash
        })),
    
        {
            text: "КОНТАКТИ",
            id: "contacts"
        },
    
    ];

    // Styles

    const headerClasses = classNames('header', {
        hide: scrollDirection === 'down',
        open: headerOpen
    });

    const openIconClasses = classNames('open-icon', {
        open: openMenu
    });

    const closeIconClasses = classNames('close-icon', {
        open: openMenu
    });

    const navigationClasses = classNames('header-nav', {
        open: openMenu
    });

    const logoClasses = classNames('header-logo', {
        open: openMenu
    });


    return (
        <header className={headerClasses}>
            <div className={logoClasses}>
                <span>TIGOIS PROJECT</span>
            </div>

            <nav className={navigationClasses}>
                <ul>
                    {
                        navData.map((nav, index) => (
                            <NavItem
                                key={index}
                                text={nav.text}
                                id={nav.id}
                                handleMenuClose={handleMenuClose}
                            />
                        ))
                    }
                </ul>
            </nav>

            <img className={openIconClasses} onClick={handleMenuOpen} src={OpenIcon} alt={'Open Menu Icon'}/>

            <img className={closeIconClasses} onClick={handleMenuClose} src={CloseIcon} alt={'Close Menu Icon'}/>
        </header>
    )
};

export default Header;
