import './WelcomeSection.scss';
import ReactPlayer from 'react-player';
import useFetch  from '../../hooks/useFetch';
import { IVideoData } from '../../types/types';

const WelcomeSection = () => {
  const {loading, error, data} = useFetch('https://strapi-app-mayl.onrender.com/api/welcome-video');

  if(loading) return <div></div>
  if(error) return <div></div>

  const videoData = (data as unknown) as IVideoData;
  
  const WELCOME_VIDEO = window.innerWidth < 750
    ? videoData.data.attributes.VideoSmall
    : videoData.data.attributes.VideoLarge;

  return (
    <section className='welcome-section'>
        <ReactPlayer
          loading="lazy"
          className='welcome-section-video'
          url={WELCOME_VIDEO}
          loop={true}
          playing={true}
          playsinline={true}
          muted={true}
          allow="autoplay; fullscreen; picture-in-picture" 
          allowFullScreen
          style={{objectFit: 'cover'}}
        />
    </section>
  )
};

export default WelcomeSection;
